let notificationSound: HTMLAudioElement;
let loopSound: HTMLAudioElement;
let playLoop: NodeJS.Timeout;

function initNotificationSounds() {
  notificationSound = new Audio(NotificationsConfig.newOrderFirstTimeSoundUrl);
  notificationSound.loop = false;
  loopSound = new Audio(NotificationsConfig.newOrderLoopSoundUrl);
  loopSound.loop = false;
}

export function activateNotificationSound() {
  const tryInterval = 1000; // 1 second
  const loopInterval = 120000; // 2 minutes

  if (!notificationSound || !loopSound) {
    initNotificationSounds();
  }

  const tryPlayLoop = setInterval(() => {
    notificationSound
      .play()
      .then(() => {
        clearInterval(tryPlayLoop);
        playLoop = setInterval(() => {
          loopSound.play();
        }, loopInterval);
      })
      .catch(() => {
        // console.info('User has not interacted with document yet.');
      });
  }, tryInterval);
}

export function deactivateNotificationSound() {
  clearInterval(playLoop);
}
